import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1090.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1090.000000) scale(0.100000,-0.100000)">
					<path d="M5160 10144 c-25 -2 -103 -9 -175 -15 -820 -68 -1610 -357 -2305
-843 -63 -44 -117 -83 -120 -86 -3 -3 -45 -37 -95 -75 -432 -334 -864 -832
-1149 -1323 -356 -616 -554 -1235 -628 -1972 -17 -164 -16 -658 1 -825 63
-633 214 -1162 486 -1705 452 -903 1199 -1651 2100 -2102 547 -275 1075 -426
1710 -489 168 -17 661 -17 830 -1 630 63 1164 216 1710 489 898 451 1636 1187
2093 2089 276 545 429 1078 494 1724 17 164 16 658 -1 825 -101 1007 -461
1870 -1096 2630 -214 255 -437 469 -765 728 -92 74 -345 240 -491 325 -580
334 -1205 535 -1894 608 -133 14 -609 26 -705 18z m435 -134 c335 -16 599 -54
915 -132 1107 -270 2087 -965 2724 -1930 135 -203 172 -267 275 -473 494 -991
616 -2114 346 -3181 -107 -420 -249 -770 -480 -1182 -48 -85 -224 -349 -302
-452 -373 -495 -860 -924 -1402 -1234 -847 -486 -1863 -688 -2836 -566 -1009
128 -1913 562 -2639 1270 -634 618 -1068 1383 -1271 2245 -157 662 -157 1428
0 2090 167 707 477 1328 952 1905 109 132 435 459 568 569 787 652 1724 1021
2720 1070 94 4 181 9 195 9 14 1 120 -3 235 -8z"/>
					<path d="M5268 9873 l-128 -4 0 -29 c0 -30 1 -30 55 -30 l55 0 0 -83 c0 -46 3
-118 6 -160 l7 -77 34 0 34 0 -2 158 -1 157 57 3 56 3 -3 32 c-3 37 10 34
-170 30z"/>
					<path d="M5584 9833 c-49 -116 -134 -327 -134 -334 0 -5 16 -9 36 -9 32 0 37
4 51 40 l16 41 76 -3 75 -3 9 -40 c6 -22 10 -41 11 -42 0 0 18 -4 39 -8 32 -6
37 -5 37 11 0 11 -24 100 -53 199 l-52 180 -47 3 c-46 3 -48 2 -64 -35z m81
-123 c10 -39 15 -73 12 -76 -2 -3 -25 -1 -49 3 l-45 8 26 68 c14 37 29 67 32
67 4 0 15 -32 24 -70z"/>
					<path d="M4990 9846 c0 -37 32 -371 35 -375 6 -5 66 12 67 19 1 3 -5 87 -12
188 l-13 182 -39 0 c-26 0 -38 -4 -38 -14z"/>
					<path d="M5895 9817 c-8 -51 -45 -330 -45 -343 0 -9 173 -38 235 -39 6 0 11
14 13 31 l3 30 -81 11 c-45 6 -82 12 -84 14 -2 2 5 73 16 157 11 85 18 155 16
156 -1 2 -17 6 -35 9 -30 6 -33 4 -38 -26z"/>
					<path d="M4695 9819 c-50 -14 -92 -64 -106 -123 -9 -43 -8 -63 5 -115 9 -35
25 -76 36 -91 48 -68 157 -77 212 -18 26 28 28 28 28 9 0 -17 6 -21 35 -21 41
0 41 1 23 128 l-11 82 -31 -1 c-42 0 -125 -14 -132 -22 -4 -3 -4 -17 0 -31 6
-24 9 -25 51 -19 44 6 45 5 45 -23 0 -16 -4 -34 -8 -40 -11 -18 -49 -34 -77
-34 -60 0 -91 43 -101 138 -6 52 -4 61 20 89 34 40 78 49 126 25 33 -16 40
-16 59 -4 30 20 26 39 -11 62 -35 21 -108 25 -163 9z"/>
					<path d="M4409 9762 c-14 -6 -14 -17 8 -107 12 -55 30 -134 39 -175 19 -89 19
-90 47 -90 45 0 50 12 33 81 -9 35 -25 107 -36 159 -11 52 -22 105 -25 118 -5
22 -32 28 -66 14z"/>
					<path d="M6307 9588 c-25 -101 -43 -186 -39 -189 4 -4 21 -9 37 -12 l30 -6 18
79 18 79 32 -11 c47 -16 50 -21 58 -104 4 -42 9 -77 11 -79 2 -1 18 -6 37 -10
l34 -7 -6 83 c-4 45 -11 87 -17 93 -16 16 -12 36 8 36 10 0 28 10 40 23 44 48
37 118 -16 149 -22 13 -174 58 -195 58 -2 0 -25 -82 -50 -182z m179 87 c31
-13 35 -18 32 -46 -4 -42 -24 -53 -82 -45 -47 7 -47 8 -41 39 15 75 24 80 91
52z"/>
					<path d="M4155 9694 c-86 -21 -165 -49 -165 -58 0 -28 105 -356 114 -356 32 1
172 52 201 74 69 53 81 144 33 244 -34 71 -113 113 -183 96z m85 -92 c49 -50
64 -141 30 -185 -21 -26 -109 -63 -121 -50 -9 10 -72 233 -68 237 12 10 69 25
97 25 24 1 42 -7 62 -27z"/>
					<path d="M6655 9500 c-31 -94 -55 -176 -52 -183 2 -7 51 -27 108 -46 57 -19
109 -36 115 -38 7 -2 16 9 22 26 l11 30 -82 27 c-45 15 -84 28 -86 30 -8 8 22
94 34 94 6 0 38 -9 70 -20 73 -25 71 -25 83 8 10 29 10 29 -59 51 -38 12 -71
26 -73 30 -7 10 15 81 25 81 4 0 39 -11 77 -25 86 -31 88 -31 100 3 l9 27
-111 37 c-61 21 -116 38 -123 38 -6 0 -37 -77 -68 -170z"/>
					<path d="M7040 9533 c-31 -12 -60 -47 -66 -80 -9 -49 11 -87 72 -133 44 -33
54 -46 54 -70 0 -23 -5 -31 -24 -36 -14 -3 -35 -3 -48 1 -27 9 -68 44 -68 59
0 6 -4 17 -9 24 -12 19 -61 -5 -61 -31 0 -55 104 -127 183 -127 57 0 107 52
107 109 0 53 -18 80 -80 122 -39 27 -50 40 -50 61 0 51 61 49 117 -5 30 -28
35 -30 58 -17 30 16 31 20 4 54 -37 47 -145 87 -189 69z"/>
					<path d="M3618 9502 c-24 -11 -38 -24 -38 -35 0 -27 31 -382 34 -384 1 -2 17
3 35 10 30 11 32 14 30 59 -2 26 -1 49 2 51 2 2 31 15 64 30 l60 26 35 -33
c34 -32 36 -33 68 -19 l34 14 -24 29 c-54 67 -244 268 -253 268 -5 0 -27 -7
-47 -16z m114 -155 l30 -37 -44 -19 c-51 -22 -51 -21 -62 79 l-8 65 27 -25
c15 -14 40 -42 57 -63z"/>
					<path d="M3381 9393 l-21 -18 40 -124 c22 -68 39 -126 37 -128 -2 -2 -46 40
-98 93 -92 93 -96 95 -122 83 -15 -7 -27 -17 -27 -23 0 -6 15 -67 34 -136 19
-69 33 -126 32 -128 -2 -1 -36 35 -77 80 -40 46 -81 92 -90 102 -16 17 -21 18
-48 6 -17 -7 -31 -17 -31 -22 0 -6 52 -67 117 -136 64 -70 121 -133 128 -140
10 -11 18 -10 48 4 21 10 37 24 37 31 0 8 -13 59 -30 115 -16 55 -30 108 -30
117 0 8 40 -27 89 -79 l90 -95 35 19 c20 11 36 26 36 35 0 9 -16 68 -35 131
-19 63 -43 141 -52 173 -17 61 -28 68 -62 40z"/>
					<path d="M7377 9371 c-67 -14 -142 -109 -153 -194 -8 -58 -1 -88 32 -127 63
-74 170 -93 241 -41 47 34 103 136 103 188 0 107 -115 197 -223 174z m77 -71
c67 -25 84 -87 45 -167 -37 -77 -94 -102 -158 -68 -57 29 -57 119 1 189 42 51
69 63 112 46z"/>
					<path d="M7662 9093 c-85 -128 -99 -166 -78 -217 29 -69 137 -127 199 -107 44
15 62 35 147 162 l70 104 -23 17 c-12 10 -27 18 -33 18 -6 0 -39 -42 -73 -93
-79 -120 -95 -137 -131 -137 -38 0 -90 44 -90 77 0 15 28 68 68 127 38 56 71
106 75 113 4 7 -5 20 -22 33 l-30 21 -79 -118z"/>
					<path d="M2828 9052 c-21 -13 -38 -30 -38 -36 1 -25 108 -370 116 -373 5 -1
20 6 33 16 23 18 24 21 12 60 -6 23 -9 45 -6 50 3 5 30 25 60 46 l55 36 33
-25 c19 -15 38 -26 43 -26 10 1 54 36 54 43 0 5 -285 211 -315 229 -5 3 -27
-6 -47 -20z m113 -114 l60 -43 -33 -27 c-18 -15 -38 -28 -45 -28 -6 0 -19 25
-27 55 -9 30 -19 61 -22 70 -9 23 2 19 67 -27z"/>
					<path d="M7967 8814 l-117 -146 24 -19 c13 -10 29 -19 34 -19 5 0 27 21 48 48
20 26 41 51 45 55 9 12 57 -20 64 -42 3 -10 -6 -49 -20 -86 l-26 -68 25 -26
c16 -16 29 -22 35 -16 15 17 51 125 51 155 0 28 3 30 38 30 64 0 112 69 91
130 -10 28 -145 151 -166 150 -4 -1 -61 -66 -126 -146z m201 12 c28 -25 28
-60 0 -80 -31 -22 -38 -20 -81 15 l-37 31 31 39 c34 43 32 43 87 -5z"/>
					<path d="M2593 8876 c-26 -22 -27 -26 -12 -43 8 -10 44 -51 79 -91 35 -40 61
-74 59 -77 -3 -2 -61 14 -129 36 -69 21 -132 39 -140 39 -8 0 -28 -12 -44 -26
-25 -22 -27 -27 -14 -42 62 -73 214 -250 225 -262 11 -13 17 -11 39 12 l27 26
-86 99 c-47 54 -84 99 -83 101 2 1 32 -7 67 -18 35 -12 101 -32 147 -46 l82
-25 30 21 c17 12 30 23 30 26 0 2 -56 69 -125 149 l-124 145 -28 -24z"/>
					<path d="M8300 8653 c-83 -55 -130 -126 -130 -195 0 -99 106 -190 199 -172 38
8 44 15 35 49 -6 24 -9 25 -50 19 -41 -6 -46 -4 -79 29 -50 50 -47 95 14 160
57 63 113 72 162 29 25 -22 32 -37 33 -68 2 -38 3 -39 36 -39 33 0 35 2 38 35
4 45 -40 114 -94 147 -47 28 -127 31 -164 6z"/>
					<path d="M3790 7110 l0 -1440 258 2 257 3 3 953 2 952 24 0 c27 0 12 38 182
-450 64 -184 150 -432 191 -550 41 -118 126 -362 188 -542 62 -179 118 -336
125 -347 10 -20 18 -21 240 -21 214 0 230 1 239 19 6 10 33 83 60 162 27 79
95 275 151 434 56 160 128 369 161 465 33 96 71 204 84 240 13 36 62 175 109
310 46 135 87 253 91 263 4 9 18 17 31 17 l24 0 0 -1095 0 -1095 277 0 276 0
-6 797 c-4 438 -7 1149 -7 1580 l0 783 -364 0 c-338 0 -364 -1 -369 -17 -3
-10 -55 -164 -117 -343 -211 -616 -232 -675 -255 -745 -13 -38 -78 -230 -145
-425 -67 -195 -139 -404 -159 -465 -31 -91 -41 -111 -59 -113 -26 -4 -14 -33
-152 368 -116 337 -263 764 -435 1265 -59 171 -119 346 -134 390 l-28 80 -371
3 -372 2 0 -1440z"/>
					<path d="M2197 8512 c-15 -15 -27 -34 -27 -41 0 -17 170 -351 179 -351 4 0 18
11 30 24 l22 24 -21 42 -22 43 51 50 50 51 36 -17 c20 -9 41 -17 48 -17 13 1
52 48 45 55 -7 7 -349 165 -357 165 -4 0 -19 -13 -34 -28z m191 -119 c9 -4 2
-17 -24 -44 l-38 -39 -33 60 c-18 33 -33 65 -33 70 0 5 26 -2 58 -16 31 -14
63 -28 70 -31z"/>
					<path d="M8508 8318 l-136 -122 76 -89 c42 -49 83 -92 90 -95 8 -2 22 4 32 14
19 19 18 20 -40 87 l-60 68 34 29 c18 17 38 30 44 30 6 0 30 -23 55 -52 31
-36 49 -49 61 -45 33 13 28 36 -20 93 l-48 56 34 31 34 30 19 -24 c10 -13 37
-43 58 -67 39 -41 40 -42 64 -26 29 19 34 11 -69 127 -38 42 -74 77 -80 76 -6
0 -73 -55 -148 -121z"/>
					<path d="M1991 8282 l-29 -38 79 -123 c44 -68 78 -125 76 -127 -2 -2 -61 16
-131 41 -70 25 -133 45 -140 45 -8 0 -26 -16 -41 -36 l-28 -36 33 -27 c145
-115 247 -193 258 -198 13 -5 52 23 52 38 0 4 -43 41 -95 81 -52 40 -91 74
-87 76 4 1 61 -16 126 -38 l118 -39 19 22 c10 12 17 27 14 33 -2 7 -33 56 -69
111 -36 55 -66 103 -66 107 0 4 45 -27 100 -70 l100 -76 20 23 c10 13 19 27
20 30 0 5 -51 46 -232 186 l-67 52 -30 -37z"/>
					<path d="M8776 8131 c-25 -26 -28 -35 -24 -78 3 -26 13 -70 24 -96 22 -57 12
-82 -32 -82 -41 0 -78 56 -71 108 l5 37 -39 0 c-39 0 -39 0 -39 -41 0 -50 22
-100 65 -144 54 -58 114 -58 172 0 41 41 42 68 7 146 -18 39 -24 67 -20 84 14
57 93 11 105 -62 6 -34 9 -38 40 -41 l34 -3 -5 44 c-14 125 -150 203 -222 128z"/>
					<path d="M5434 5397 c-2 -8 -3 -164 -2 -348 l3 -334 39 -59 c21 -33 56 -96 76
-140 206 -446 181 -943 -69 -1376 l-51 -88 2 -259 3 -258 640 0 c617 0 644 1
762 22 442 81 741 288 914 633 100 199 145 404 155 705 26 740 -259 1234 -821
1423 -94 32 -226 62 -270 62 l-25 0 0 -248 0 -248 53 -17 c136 -43 292 -175
358 -303 91 -173 114 -298 114 -604 -1 -219 -2 -243 -28 -350 -55 -238 -175
-407 -352 -497 -167 -86 -276 -103 -647 -103 l-288 0 0 960 0 960 80 0 80 0 0
240 0 240 -360 0 c-290 0 -362 -3 -366 -13z"/>
					<path d="M6210 5125 l0 -235 270 0 270 0 0 235 0 235 -270 0 -270 0 0 -235z" />
					<path d="M4050 5025 c-226 -39 -378 -110 -578 -272 -9 -7 -13 -16 -9 -19 4 -4
13 -1 21 5 8 7 20 11 28 9 7 -2 57 23 111 55 54 33 134 74 178 94 320 138 739
103 1030 -88 47 -31 101 -60 120 -64 19 -4 46 -14 62 -21 69 -36 -7 36 -118
110 -256 172 -558 240 -845 191z"/>
					<path d="M4055 4904 c-77 -16 -115 -31 -115 -43 0 -13 89 -65 97 -57 4 3 0 6
-8 6 -8 0 -3 6 11 13 17 9 27 10 32 3 5 -8 8 -7 8 0 0 18 117 34 180 24 59 -8
64 -7 75 27 5 19 1 25 -20 33 -36 14 -187 10 -260 -6z"/>
					<path d="M6210 4340 l0 -530 275 0 275 0 0 530 0 530 -275 0 -275 0 0 -530z" />
					<path d="M3723 4781 l-23 -19 33 1 c21 1 51 -12 88 -35 42 -27 60 -33 68 -25
15 15 14 27 -2 27 -8 0 -19 11 -25 24 -6 14 -22 26 -40 29 -16 3 -40 8 -53 11
-15 4 -32 -1 -46 -13z"/>
					<path d="M4720 4768 c-53 -15 -64 -32 -15 -24 22 3 51 6 64 6 20 0 21 2 11 15
-14 17 -12 17 -60 3z"/>
					<path d="M3807 4685 c3 -8 9 -12 14 -9 12 7 11 11 -5 17 -10 4 -13 1 -9 -8z" />
					<path d="M4767 4683 c-4 -3 -7 -19 -7 -35 0 -22 4 -28 22 -28 l22 0 -22 -16
c-12 -8 -22 -21 -22 -27 0 -7 -16 -25 -35 -40 -28 -23 -42 -27 -65 -22 -17 4
-30 13 -30 21 0 8 -4 14 -10 14 -5 0 -10 -11 -10 -25 0 -17 -4 -23 -14 -19
-24 9 -27 -12 -3 -23 12 -6 69 -26 127 -44 205 -64 376 -152 483 -246 22 -19
41 -32 44 -29 12 12 -64 176 -125 271 -71 111 -158 207 -214 236 -36 18 -127
27 -141 12z"/>
					<path d="M4680 4673 c-14 -2 -45 -11 -68 -20 -37 -13 -43 -19 -38 -37 3 -11 6
-24 6 -28 0 -4 11 -3 25 2 20 8 29 5 52 -16 33 -30 46 -20 47 38 1 24 5 49 10
56 4 6 4 11 -1 11 -4 -1 -19 -4 -33 -6z"/>
					<path d="M5070 4645 c-10 -12 -10 -19 1 -34 132 -179 216 -341 245 -476 9 -38
25 -94 37 -124 16 -41 19 -55 10 -58 -9 -3 -13 -33 -13 -103 0 -179 -61 -399
-163 -589 -30 -54 -36 -73 -24 -68 16 6 16 4 1 -26 l-16 -32 26 27 c95 99 191
317 221 503 46 280 -12 571 -161 817 -41 67 -130 178 -143 178 -5 0 -14 -7
-21 -15z"/>
					<path d="M3239 4477 c-38 -55 -98 -176 -119 -241 -10 -31 -10 -46 0 -77 16
-49 26 -49 35 -2 3 20 20 70 36 111 28 71 29 75 12 88 -17 13 -16 15 12 39 52
43 57 52 59 94 1 22 2 41 2 41 -1 0 -17 -24 -37 -53z"/>
					<path d="M4572 4412 c-7 -5 -10 -16 -6 -25 3 -10 1 -17 -6 -17 -7 0 -9 -8 -5
-20 5 -14 2 -20 -9 -20 -9 0 -16 -5 -16 -11 0 -7 8 -6 23 3 46 30 70 29 110
-6 25 -23 35 -39 31 -50 -9 -23 13 -20 26 5 9 16 8 26 -5 45 -17 26 -14 29 16
14 10 -6 19 -20 19 -31 0 -22 28 -69 41 -69 5 0 9 11 9 24 0 36 20 43 42 15
10 -13 22 -29 27 -35 5 -6 27 -14 49 -18 52 -8 60 -21 31 -53 -25 -26 -45 -27
-145 -7 -21 4 -36 1 -47 -10 -22 -22 -24 -21 -73 24 -39 36 -49 40 -95 40 -87
0 -124 -17 -190 -88 -65 -70 -112 -160 -103 -197 3 -13 -1 -33 -10 -48 -15
-22 -15 -27 8 -78 13 -30 46 -79 74 -109 45 -50 51 -54 67 -40 10 8 34 16 54
18 131 9 167 -6 157 -70 -5 -31 -2 -41 25 -67 31 -31 49 -39 49 -21 0 15 142
12 189 -5 22 -8 41 -12 41 -9 0 2 21 -11 47 -30 l47 -34 60 65 c34 37 82 95
109 130 26 34 52 63 56 63 14 0 22 66 22 186 0 134 -7 153 -93 237 -117 114
-335 228 -548 285 -19 6 -42 13 -50 16 -8 3 -21 2 -28 -2z m428 -404 c0 -9 6
-18 13 -21 6 -2 9 -8 5 -12 -9 -10 -38 19 -38 39 0 8 -4 18 -10 21 -5 3 -10
14 -10 23 0 13 5 12 20 -8 11 -14 20 -32 20 -42z"/>
					<path d="M3243 4217 c-12 -29 -29 -85 -37 -125 -15 -68 -15 -74 1 -99 l18 -27
3 31 c4 37 56 83 95 83 14 0 27 7 31 15 8 21 53 9 89 -23 l27 -26 0 42 c0 23
-4 42 -10 42 -18 0 -25 3 -62 26 -21 13 -40 24 -44 24 -3 0 -12 6 -20 14 -7 8
-24 23 -37 33 -13 10 -26 24 -28 30 -2 6 -14 -12 -26 -40z"/>
					<path d="M4485 4259 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
					<path d="M3073 4054 c-13 -48 -9 -353 6 -419 34 -152 96 -306 167 -415 l29
-45 3 48 c2 34 -2 57 -17 80 -44 72 -102 223 -122 319 -16 77 -22 142 -23 258
-1 128 -4 159 -18 179 l-17 24 -8 -29z"/>
					<path d="M3180 3888 c0 -15 5 -30 12 -34 8 -5 9 -1 5 12 -7 18 -7 18 8 0 8
-11 15 -15 15 -9 0 6 -9 21 -20 34 l-19 24 -1 -27z"/>
					<path d="M3796 3645 c18 -13 18 -14 1 -15 -10 0 -16 -4 -13 -8 3 -5 0 -26 -6
-48 -7 -21 -12 -42 -12 -46 -1 -3 -5 -10 -10 -15 -5 -5 -6 -3 -2 4 5 9 2 11
-8 7 -9 -3 -16 -8 -16 -9 0 -8 142 -96 209 -130 59 -29 76 -34 83 -23 25 37
38 70 38 96 0 27 -7 33 -105 80 -58 28 -108 59 -112 69 -8 21 -42 53 -56 53
-6 0 -2 -7 9 -15z"/>
					<path d="M5251 3604 c0 -6 -33 -55 -72 -109 -39 -53 -68 -99 -65 -103 3 -3 6
0 6 7 0 9 3 9 14 -1 7 -7 13 -25 12 -40 -3 -39 8 -27 46 54 50 104 71 163 65
184 -3 11 -5 14 -6 8z"/>
					<path d="M3689 3447 c-1 -6 -1 -16 0 -20 1 -5 -3 -6 -8 -2 -6 3 -28 -6 -50
-21 -28 -18 -55 -27 -88 -28 -66 -2 -66 -21 1 -96 71 -80 74 -123 6 -98 -41
16 -100 20 -100 8 0 -6 -5 -8 -11 -5 -6 5 -4 12 8 22 17 13 16 14 -12 9 -16
-3 -24 -3 -17 0 18 8 14 20 -8 21 -11 1 -20 -3 -20 -9 0 -20 225 -234 290
-275 129 -82 386 -162 484 -151 57 7 271 110 286 138 9 17 8 20 -12 20 -19 0
-20 2 -8 10 8 5 10 10 4 10 -6 0 -21 24 -34 53 -13 28 -30 60 -38 69 -8 10
-12 24 -9 32 5 12 -13 21 -77 41 -77 24 -116 25 -116 4 0 -14 18 -11 23 4 2 6
8 9 12 5 17 -16 -8 -27 -50 -21 -25 3 -57 1 -72 -5 -16 -7 -44 -13 -63 -15
-19 -2 -46 -9 -59 -16 -16 -9 -26 -10 -34 -2 -7 7 -5 12 7 17 9 4 19 4 22 1
10 -10 41 26 56 63 20 52 13 76 -30 94 -43 18 -166 84 -234 127 -31 18 -48 24
-49 16z m-169 -277 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z"/>
					<path d="M3350 3293 c0 -5 9 -18 20 -28 11 -10 20 -14 20 -8 0 5 -9 18 -20 28
-11 10 -20 14 -20 8z"/>
					<path d="M3320 3155 c-10 -12 -5 -24 25 -65 50 -68 174 -180 232 -209 26 -14
62 -34 78 -46 83 -61 136 -79 243 -83 62 -3 102 0 102 6 0 5 -19 12 -43 15
-57 8 -223 78 -305 129 -89 55 -132 90 -227 187 -44 44 -83 81 -86 81 -4 0
-12 -7 -19 -15z"/>
					<path d="M4435 2849 c-49 -27 -84 -49 -77 -49 24 0 187 42 197 51 5 6 4 9 -4
9 -7 0 -9 4 -6 10 3 6 -1 7 -9 4 -9 -3 -16 -2 -16 4 0 6 6 13 13 15 6 3 8 5 2
5 -5 0 -50 -22 -100 -49z"/>
					<path d="M4626 2804 c-95 -33 -262 -63 -371 -67 -92 -4 -232 -34 -218 -47 16
-15 203 -21 310 -10 136 13 219 33 309 75 76 35 93 49 68 59 -8 3 -12 2 -9 -4
3 -6 2 -10 -4 -10 -5 0 -11 6 -13 14 -2 10 -17 8 -72 -10z"/>
					<path d="M2515 2478 c-27 -29 -84 -90 -127 -135 -43 -46 -75 -88 -73 -95 3 -7
15 -19 26 -27 20 -12 26 -8 74 39 46 46 55 51 74 41 34 -19 36 -54 5 -127
l-27 -65 26 -27 c15 -14 28 -24 30 -21 16 20 60 140 60 161 0 26 2 27 37 22
30 -5 42 -1 67 20 66 56 51 117 -51 209 -36 31 -67 57 -69 57 -2 0 -26 -24
-52 -52z m109 -77 c36 -38 33 -81 -7 -95 -22 -8 -32 -6 -54 10 -48 36 -51 50
-18 84 36 37 44 37 79 1z"/>
					<path d="M8292 2507 l-22 -23 81 -87 c45 -49 79 -90 77 -92 -2 -3 -62 11 -131
30 -70 19 -133 35 -140 35 -7 -1 -25 -11 -41 -25 l-30 -24 37 -43 c20 -24 79
-88 131 -143 l94 -100 26 25 26 25 -86 93 c-48 51 -84 95 -82 97 2 3 68 -13
147 -35 l142 -40 30 21 c17 12 29 25 27 30 -5 14 -249 279 -257 279 -4 0 -17
-10 -29 -23z"/>
					<path d="M2710 2159 c-62 -78 -113 -148 -114 -155 0 -7 42 -47 94 -88 88 -70
96 -74 112 -59 10 9 18 21 18 27 0 6 -29 34 -65 61 -35 28 -64 55 -64 60 0 6
12 26 28 45 l29 34 62 -48 62 -49 19 24 19 24 -37 30 c-21 17 -48 39 -62 51
l-24 20 30 34 30 33 67 -53 c65 -51 67 -52 86 -35 11 10 20 21 20 25 0 5 -112
97 -186 152 -8 5 -50 -40 -124 -133z"/>
					<path d="M7925 2193 c-131 -68 -142 -206 -26 -322 71 -71 179 -71 248 1 43 46
53 69 53 123 0 105 -102 215 -199 215 -25 -1 -59 -8 -76 -17z m120 -75 c43
-29 85 -98 85 -137 0 -48 -46 -91 -99 -91 -34 0 -46 6 -80 42 -76 79 -79 154
-6 196 31 18 66 14 100 -10z"/>
					<path d="M2967 1948 c-60 -88 -107 -161 -105 -163 2 -1 48 -32 103 -69 l100
-66 17 22 c9 12 14 26 12 30 -3 5 -33 27 -67 50 -34 22 -63 42 -65 44 -2 1 33
57 78 124 44 66 83 127 86 134 3 8 -7 23 -23 34 l-28 19 -108 -159z"/>
					<path d="M7660 2003 c-14 -9 -26 -17 -28 -19 -2 -1 45 -74 103 -163 l105 -162
30 21 30 22 -22 37 c-47 77 -183 281 -188 281 -3 0 -17 -8 -30 -17z"/>
					<path d="M7458 1882 c-64 -37 -119 -70 -123 -74 -4 -3 1 -18 10 -32 l17 -25
39 24 c60 39 47 50 149 -132 34 -62 65 -113 69 -113 5 0 33 17 59 36 2 1 -10
24 -26 50 -67 114 -122 214 -122 224 0 5 18 19 40 30 22 11 40 25 40 31 0 15
-20 49 -28 48 -4 0 -60 -30 -124 -67z"/>
					<path d="M3241 1797 c-47 -78 -91 -152 -98 -163 -13 -20 -11 -23 20 -41 l33
-20 97 163 c53 90 97 165 97 168 0 5 -55 36 -62 36 -2 0 -41 -64 -87 -143z"/>
					<path d="M3419 1693 c-66 -65 -123 -120 -127 -123 -4 -3 -13 -13 -20 -22 -12
-15 -9 -19 18 -33 32 -17 33 -16 66 16 l34 33 65 -32 66 -32 -7 -50 c-6 -48
-6 -49 27 -63 19 -8 35 -14 36 -13 3 2 53 359 53 380 0 16 -11 27 -35 39 -20
9 -40 17 -46 17 -6 0 -65 -53 -130 -117z m126 -41 c-9 -84 -14 -88 -65 -62
l-43 22 54 54 c29 29 55 52 57 49 2 -2 1 -30 -3 -63z"/>
					<path d="M7156 1725 c-44 -19 -83 -38 -87 -42 -5 -5 22 -74 60 -154 38 -79 71
-155 75 -167 4 -12 14 -22 22 -22 13 0 50 15 62 25 2 2 -10 30 -27 63 -33 66
-35 61 33 97 75 38 98 103 62 173 -18 34 -62 62 -98 62 -13 0 -59 -16 -102
-35z m130 -59 c24 -38 14 -67 -31 -93 -22 -12 -42 -23 -46 -23 -6 0 -49 86
-49 100 0 14 52 39 82 40 18 0 33 -8 44 -24z"/>
					<path d="M3787 1683 c-10 -16 -137 -332 -137 -340 0 -10 198 -84 223 -83 34 0
79 31 93 65 19 47 18 68 -7 99 -12 15 -20 28 -18 30 2 1 16 7 31 14 19 8 31
23 39 51 20 66 -12 97 -152 151 -60 22 -66 23 -72 13z m113 -91 c43 -21 53
-62 20 -85 -19 -14 -27 -14 -66 0 -49 17 -50 19 -30 68 17 39 26 41 76 17z
m-50 -147 c54 -27 63 -46 43 -87 -16 -33 -43 -36 -105 -11 -24 9 -44 17 -46
18 -1 1 4 17 11 36 8 19 17 42 20 52 8 23 18 22 77 -8z"/>
					<path d="M6933 1630 c-13 -5 -23 -13 -23 -17 0 -7 129 -342 135 -351 1 -1 18
4 37 12 l36 15 -71 176 c-38 96 -75 175 -81 174 -6 0 -21 -4 -33 -9z"/>
					<path d="M4072 1388 c-29 -95 -53 -178 -52 -184 0 -14 220 -79 230 -68 5 5 10
18 12 29 2 17 -9 24 -74 44 -43 14 -78 30 -78 36 0 6 20 73 45 150 24 77 41
143 37 147 -4 4 -21 10 -37 13 l-30 6 -53 -173z"/>
					<path d="M6653 1533 c-40 -13 -73 -29 -73 -35 0 -12 110 -358 114 -358 15 0
66 24 66 31 0 6 -10 39 -21 76 -24 73 -20 83 38 83 28 0 37 -8 74 -61 40 -56
45 -60 73 -55 17 4 34 10 38 14 9 9 -83 131 -111 146 l-22 12 21 19 c34 31 24
109 -19 142 -25 19 -98 13 -178 -14z m131 -49 c22 -21 20 -67 -3 -81 -10 -6
-34 -15 -53 -18 -33 -6 -35 -5 -46 33 -17 56 -16 59 26 70 53 14 59 14 76 -4z"/>
					<path d="M4405 1447 c-4 -18 -22 -101 -42 -185 -19 -85 -33 -155 -31 -156 1
-2 56 -16 122 -31 98 -22 120 -25 127 -14 20 31 9 47 -38 58 -27 7 -65 17 -86
22 l-39 10 7 42 c4 23 10 45 14 50 4 4 38 1 75 -8 74 -17 86 -15 86 20 0 24
-7 28 -70 40 -73 13 -73 14 -67 57 3 22 7 42 10 44 3 3 42 -4 88 -16 88 -22
102 -18 97 25 -3 15 -20 23 -98 41 -161 38 -148 38 -155 1z"/>
					<path d="M6357 1455 c-102 -36 -139 -128 -102 -252 26 -89 113 -146 196 -129
53 10 98 37 119 71 34 55 23 85 -31 85 -12 0 -19 -7 -19 -20 0 -34 -44 -70
-85 -70 -51 0 -74 15 -96 63 -21 47 -25 134 -6 158 31 41 90 50 138 22 28 -17
30 -17 51 4 18 19 19 24 8 38 -36 44 -103 55 -173 30z"/>
					<path d="M5999 1391 c-45 -14 -68 -31 -84 -61 -44 -86 18 -160 135 -160 42 0
80 -24 80 -49 0 -50 -94 -70 -145 -30 -22 18 -29 20 -36 8 -29 -43 -29 -47 -3
-68 77 -61 230 -27 254 56 26 87 -31 153 -133 153 -27 0 -57 5 -68 10 -21 12
-25 50 -7 68 14 14 104 16 113 2 11 -18 24 -11 40 20 l16 31 -35 14 c-38 16
-85 18 -127 6z"/>
					<path d="M4896 1379 c-53 -9 -86 -47 -86 -99 0 -53 37 -92 115 -123 49 -19 60
-28 63 -48 4 -39 -13 -54 -58 -53 -33 1 -49 9 -76 35 l-34 33 -22 -21 c-30
-31 -13 -64 47 -93 37 -18 58 -21 107 -18 53 4 65 9 89 36 33 37 38 85 14 131
-13 27 -87 71 -118 71 -22 0 -57 30 -57 50 0 44 91 51 135 10 l21 -20 24 25
c19 21 21 27 10 40 -29 35 -108 55 -174 44z"/>
					<path d="M5157 1353 c-13 -12 -7 -296 6 -322 21 -43 60 -63 128 -68 49 -3 72
0 98 14 55 29 68 68 75 231 l6 143 -37 -3 -38 -3 -5 -137 c-7 -160 -15 -178
-84 -178 -80 0 -90 25 -81 190 l7 127 -23 6 c-31 9 -44 9 -52 0z"/>
					<path d="M5611 1353 l-63 -4 6 -92 c3 -51 8 -137 12 -193 l6 -100 86 4 c148 7
195 29 208 98 8 42 -20 102 -52 110 -29 7 -29 9 -4 41 27 34 25 76 -3 109 -26
30 -67 35 -196 27z m125 -61 c6 -4 14 -17 18 -29 11 -35 -24 -63 -79 -63 l-45
0 0 50 0 50 48 0 c26 0 52 -4 58 -8z m45 -163 c5 -8 8 -27 7 -44 -4 -37 -36
-55 -100 -55 l-48 0 0 54 c0 49 3 55 23 59 38 9 109 0 118 -14z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
